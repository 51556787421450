import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/solid'
import { useLocations } from 'context'
import { Button, Card, LocationMap } from 'components'
import { arrayToJson } from 'helpers/ApplicationHelper'
import { getLocationStats } from 'helpers/BackendHelper'
import { TextInput } from 'components'

const Locations = () => {
  //  --- Variables ---
  const { t } = useTranslation()
  const { locations, saveLocation, updateLocation } = useLocations()
  const [editing, setEditing] = useState(false)
  const [currentLocation, setCurrentLocation] = useState()

  useEffect(() => {
    setCurrentLocation(locations[0])
  }, [locations])

  const onSave = async (name, coordinates) => {
    console.log('Locatoin save', name, coordinates)
    if (currentLocation.id == null) {
      await saveLocation(coordinates, name)
    } else {
      await updateLocation(
        {
          ...currentLocation,
          name,
          coordinates: arrayToJson(coordinates),
          stats: getLocationStats(coordinates),
        },
        currentLocation.id
      )
    }

    setEditing(false)
  }

  const onNewLocation = () => {
    setCurrentLocation({
      name: '',
      coordinates: [],
    })
    setEditing(true)
  }

  //  --- Response ---
  return (
    <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5'>
      <div className='flex flex-col w-full lg:w-1/3 xl:w-1/4 space-y-5'>
        {currentLocation && locations.length > 0 && (
          <Card innerPadding='py-3 px-8'>
            <div className='flex flex-col space-y-1'>
              {locations.map((location) => (
                <button
                  onClick={() => setCurrentLocation(location)}
                  key={location.name}
                  className={`cursor-pointer ${
                    location.id === currentLocation.id
                      ? 'bg-indigo-400 hover:bg-indigo-500 text-white'
                      : 'hover:bg-gray-100'
                  } rounded-lg focus:outline-none font-bold outline-none py-3 space-x-2 text-xs uppercase`}>
                  {location.name}
                </button>
              ))}
            </div>
          </Card>
        )}
        <Card>
          <Button disabled={editing} className='mx-auto' onClick={onNewLocation}>
            <PlusIcon className='w-4 h-4' />
            {t('map:newLocation')}
          </Button>
        </Card>
      </div>
      <Card className='w-full lg:w-2/3 xl:w-3/4'>
        {currentLocation ? (
          <LocationView
            editable={editing}
            location={currentLocation}
            toggle={() => setEditing((e) => !e)}
            onSave={onSave}
            reset={(e) => {
              setCurrentLocation((c) => {
                return c.id != null ? { ...c } : locations[0]
              })
            }}
          />
        ) : null}
      </Card>
    </div>
  )
}

export default Locations

const LocationView = ({ location, editable, onSave, reset, toggle }) => {
  const mapRef = useRef()
  const [name, setName] = useState()

  useEffect(() => {
    setName(`${location.name}`)
  }, [location])

  const onCancel = () => {
    reset()
    toggle()
  }

  return (
    <>
      <div className={`flex flex-row space-x-4 pb-2`}>
        {editable ? (
          <>
            <TextInput
              className='text-2xl py-2'
              value={name}
              placeholder={'Name this location'}
              onChange={(e) => setName(e.target.value)}
            />
            <Button className='my-auto' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className='my-auto'
              onClick={(e) => onSave(name, mapRef.current.getCoordinates())}>
              Save
            </Button>
          </>
        ) : (
          <>
            <p className='my-auto text-2xl py-2 border border-transparent flex-grow'>{name}</p>
            <Button className='my-auto' onClick={toggle}>
              Edit
            </Button>
          </>
        )}
      </div>
      <LocationMap
        ref={mapRef}
        defaultCenter={{ lat: -29.91275, lng: 23.998791 }}
        height={500}
        editable={editable}
        location={location}
      />
    </>
  )
}
