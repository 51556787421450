import { useCallback, useEffect, useState } from 'react'
import { Collections } from '../config/Constants'
import { useAuth } from './AuthContext'
import firebase from '../lib/firebase'

const useMachines = () => {
  //  --- Variables ---
  const { userMetadata: user } = useAuth()
  const [machines, setMachines] = useState([])

  //  --- Functionality ---
  const createCallback = useCallback(
    (obj) => {
      if (!user || !obj) return Promise.resolve()

      return firebase
        .firestore()
        .collection(Collections.MACHINES)
        .add({
          ...obj,
          createdBy: user.userId,
          createdAt: Date.now(),
          orgId: user.orgId,
        })
    },
    [user]
  )

  useEffect(() => {
    if (user == null) return

    firebase
      .firestore()
      .collection(Collections.MACHINES)
      .where('orgId', '==', user.orgId)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) return

        setMachines(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      }, console.error)
  }, [user])

  const updateCallback = useCallback(
    (body, id) => {
      if (!user || !body || !id) return

      return firebase
        .firestore()
        .collection(Collections.MACHINES)
        .doc(id)
        .set({ ...body, updatedAt: Date.now() })
    },
    [user]
  )

  //  --- Response ---
  return {
    machines,
    saveMachine: createCallback,
    updateMachine: updateCallback,
  }
}

export default useMachines
