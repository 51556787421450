import React, { useState } from 'react'
import { Card, ReactTable } from 'components'
import { CheckCircleIcon } from '@heroicons/react/solid'
import MenuDropdown from 'components/Dropdowns/MenuDropdown'
import { Modal } from 'components/Modal'
import { TextInput } from 'components'
import { Button } from 'components'
import useUsers from 'context/useUsers'
import toast from 'react-hot-toast'
import * as yup from 'yup'

const updateShape = {
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email(),
  roles: yup.array(yup.string()),
}

const EditUserForm = ({ user, onClose, onSubmit }) => {
  const [form, setState] = useState(user)
  const [error, setError] = useState(false)

  const submit = () => {
    let schema = yup.object().shape(updateShape)
    if (!form.userId) {
      schema = yup.object().shape({
        password: yup.string().required(),
        ...updateShape,
      })
    }

    try {
      schema.validateSync(form)
    } catch (err) {
      setError(true)
      return
    }

    onSubmit(form)
  }

  const setForm = (...e) => {
    setError(false)
    setState(...e)
  }

  const onPermission = (e) => {
    if (e.target.checked) {
      setForm((f) => ({ ...f, roles: [...f.roles, e.target.value] }))
    } else {
      setForm((f) => {
        return {
          ...f,
          roles: [...f.roles.filter((r) => r !== e.target.value)],
        }
      })
    }
  }

  return (
    <>
      <div className='flex flex-col justify-between mt-3 gap-4'>
        {error && <div className='text-red-500'>Some fields are invalid</div>}
        <div>
          <span className='flex flex-col justify-center w-full'>Email:</span>
          <div className='flex flex-row justify-between gap-3'>
            <TextInput
              value={form.email}
              required={true}
              onChange={(e) => setForm((f) => ({ ...f, email: e.target.value }))}
              disabled={!!form.userId}
            />
          </div>
        </div>
        {!form.userId ? (
          <div>
            <span className='flex flex-col justify-center w-full'>Password:</span>
            <div className='flex flex-row justify-between gap-3'>
              <TextInput
                required={true}
                value={form.password}
                onChange={(e) => setForm((f) => ({ ...f, password: e.target.value }))}
              />
            </div>
          </div>
        ) : null}
        <div>
          <span className='flex flex-col justify-center w-full'>First name:</span>
          <div className='flex flex-row justify-between'>
            <TextInput
              required={true}
              value={form.firstName}
              onChange={(e) => setForm((f) => ({ ...f, firstName: e.target.value }))}
            />
          </div>
        </div>
        <div>
          <span className='flex flex-col justify-center w-full'>Last name:</span>
          <div className='flex flex-row justify-between'>
            <TextInput
              required={true}
              value={form.lastName}
              onChange={(e) => setForm((f) => ({ ...f, lastName: e.target.value }))}
            />
          </div>
        </div>
        <span className='mt-2 font-semibold'>Permissions:</span>
        <div className='flex flex-row justify-between gap-3'>
          <span className='flex flex-col justify-center w-full'>Verify faces:</span>
          <TextInput
            type='checkbox'
            value='VERIFY_FACE'
            checked={form.roles.includes('VERIFY_FACE')}
            onChange={onPermission}
          />
        </div>
        <div className='flex flex-row justify-between gap-3'>
          <span className='flex flex-col justify-center w-full'>Force stop sessions:</span>
          <TextInput
            type='checkbox'
            value='FORCE_STOP'
            checked={form.roles.includes('FORCE_STOP')}
            onChange={onPermission}
          />
        </div>
        <div className='flex flex-row justify-between gap-3'>
          <span className='flex flex-col justify-center w-full'>See all sessions:</span>
          <TextInput
            type='checkbox'
            value='ALL_SNAPS'
            checked={form.roles.includes('ALL_SNAPS')}
            onChange={onPermission}
          />
        </div>
        <div className='flex flex-row justify-between gap-3'>
          <span className='flex flex-col justify-center w-full'>Admin:</span>
          <TextInput
            type='checkbox'
            value='ADMIN'
            checked={form.roles.includes('ADMIN')}
            onChange={onPermission}
          />
        </div>
        <div className='flex flex-row justify-between gap-3'>
          <span className='flex flex-col justify-center w-full'>Manage employees:</span>
          <TextInput
            type='checkbox'
            value='ADD_FACE'
            checked={form.roles.includes('ADD_FACE')}
            onChange={onPermission}
          />
        </div>
      </div>
      <div className='flex flex-row justify-end space-x-2 mt-3'>
        <Button secondary={true} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={submit}>Save</Button>
      </div>
    </>
  )
}

export default function Tables() {
  const [selected, setSelected] = useState()

  const { users, refetch, updateUser, createUser, toggleDisabled } = useUsers()

  const onEdit = (row) => {
    setSelected({
      ...row,
    })
  }

  const onSubmitUser = async (form) => {
    setSelected()
    const apiCall = !form.userId ? createUser : updateUser
    await toast.promise(apiCall({ ...form }), {
      success: 'Saved changes',
      error: 'Could not update user',
    })
    refetch()
  }

  const cancelEdit = () => {
    setSelected()
  }

  const onDisabled = async (uid, disabled) => {
    await toast.promise(toggleDisabled({ userId: uid, disabled }), {
      success: 'Saved changes',
      error: 'Could not update user',
    })
    refetch()
  }

  return (
    <Card>
      <div className='flex flex-row space-x-2 justify-end'>
        <Button onClick={(e) => setSelected({ roles: [], email: '' })}>Add User</Button>
      </div>
      <ReactTable
        columns={columns(onEdit, onDisabled)}
        data={users}
        initialSort={{ id: 'fullName', desc: true }}
        overrideOnClick={[6]}
      />

      <Modal isOpen={!!selected} setIsOpen={cancelEdit} title='Edit user' size={'1/2'}>
        {selected ? (
          <EditUserForm user={selected} onClose={cancelEdit} onSubmit={onSubmitUser} />
        ) : null}
      </Modal>
    </Card>
  )
}

const columns = (onEdit, onDisabled) => [
  {
    i18n: 'snapshots:name',
    accessor: 'fullName',
    Header: 'Name',
    Cell: ({ row }) => (
      <div className='flex flex-col justify-center h-full'>
        {`${row.original.firstName} ${row.original.lastName}`.trim()}
      </div>
    ),
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: ({ row }) => (
      <div className='flex flex-col justify-center h-full'>{row.original.email}</div>
    ),
  },
  {
    accessor: 'roles',
    Header: 'Permissions',
    Cell: ({ row }) => (
      <div className='flex flex-col justify-center h-full'>
        <i>{row.original.roles.length} permissions</i>
      </div>
    ),
  },
  {
    accessor: 'active',
    Header: 'Enabled',
    Cell: ({ row }) => (
      <div className='flex flex-col justify-center h-full'>
        <CheckCircleIcon
          className={`h-4 w-4 ${
            row.original.disabled ? 'text-gray-300' : 'text-green-400'
          }  my-auto ml-2`}
        />
      </div>
    ),
  },
  {
    i18n: 'snapshots:location',
    Header: 'Actions',
    accessor: 'actions',
    width: 100,
    Cell: ({ row: { original } }) => (
      <div>
        <MenuDropdown
          variant='secondary'
          items={[
            {
              label: 'Edit',
              onClick: (e) => {
                e.preventDefault()
                onEdit(original)
              },
            },
            {
              label: original.disabled ? 'Enable account' : 'Disable account',
              onClick: (e) => {
                e.preventDefault()
                onDisabled(original.userId, !original.disabled)
              },
            },
          ]}>
          Actions
        </MenuDropdown>
      </div>
    ),
  },
]

/*
-> Filter snaps
-> Show total
-> add date filter
*/
