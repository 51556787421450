import React from 'react'
import PropTypes from 'prop-types'
import SmallCard from './SmallCard'
import {
  LocationMarkerIcon,
  ClockIcon,
  BadgeCheckIcon,
  DeviceMobileIcon,
} from '@heroicons/react/solid'

const IconMap = {
  badge: BadgeCheckIcon,
  clock: ClockIcon,
  mobile: DeviceMobileIcon,
  location: LocationMarkerIcon,
}

export default function StatsCard({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescription,
  statIconName,
  statIconColor,
}) {
  const Icon = IconMap[statIconName]
  return (
    <SmallCard>
      <div className='break-words xl:mb-0'>
        <div className='flex flex-wrap'>
          <div className='relative w-full pr-4 max-w-full flex-grow flex-1'>
            <h5 className='text-gray-500 uppercase font-bold text-xs'>{statSubtitle}</h5>
            <span className='font-semibold text-xl text-gray-800'>{statTitle}</span>
          </div>
          <div
            className={
              'text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full ' +
              statIconColor
            }>
            <Icon className='w-5 h-5' />
          </div>
        </div>
        <p className='text-sm text-gray-500 mt-4'>
          <span className={statPercentColor + ' mr-2'}>
            <i
              className={
                statArrow === 'up'
                  ? 'fas fa-arrow-up'
                  : statArrow === 'down'
                  ? 'fas fa-arrow-down'
                  : ''
              }></i>{' '}
          </span>
          <span className='whitespace-no-wrap'>{statDescription}</span>
        </p>
      </div>
    </SmallCard>
  )
}

StatsCard.defaultProps = {
  statTitle: '350,897',
  statPercentColor: 'text-green-500',
  statIconName: 'far fa-chart-bar',
  statIconColor: 'bg-red-500',
}

StatsCard.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(['up', 'down']),
  statPercent: PropTypes.string,
  statPercentColor: PropTypes.string,
  statDescription: PropTypes.string,
  statIconName: PropTypes.string,
  statIconColor: PropTypes.string,
}
