import React from 'react'
import { Toaster } from 'react-hot-toast'

//  --- Context ---
import { AuthenticationProvider } from 'context'
import Navigation from './navigation'

export default function AppRoot() {
  return (
    <AuthenticationProvider>
      <Toaster />
      <Navigation />
    </AuthenticationProvider>
  )
}
