import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { useAuth, AuthenticationProvider } from 'context'

import Admin from 'layouts/Admin.js'
import Auth from 'layouts/Auth.js'

import Landing from 'views/Landing.js'

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) return <Component {...rest} />
      return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
    }}
  />
)

export default function Navigation() {
  const { isAuthenticated } = useAuth()

  return (
    <AuthenticationProvider>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute {...{ isAuthenticated }} path='/admin' component={Admin} />
          <Route path='/auth' component={Auth} />
          <Route path='/landing' exact component={Landing} />
          <Redirect from='*' to='/admin' />
        </Switch>
      </BrowserRouter>
    </AuthenticationProvider>
  )
}
