import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useEffect, useState } from 'react'

const MarkerCluster = ({ map, markers }) => {
  const [cluster, setCluster] = useState()

  useEffect(() => {
    if (markers.length > 0) {
      const mm = markers.map((m) => new window.google.maps.Marker({ ...m }))
      setCluster(new MarkerClusterer({ map, markers: mm }))
    }

    // remove marker from map on unmount
    return () => {
      if (cluster) {
        cluster.setMap(null)
      }
    }
  }, [map, markers])

  return null
}

export default MarkerCluster
