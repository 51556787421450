import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

//  --- Components ---
import Navbar from 'components/Navbars/AuthNavbar.js'
import FooterSmall from 'components/Footers/FooterSmall.js'
import BackgroundImage from 'assets/img/register_bg_2.png'
import './auth.css'

//  --- Views ---
import Login from 'views/auth/Login.js'

export default function Auth() {
  //  --- Response ---
  return (
    <>
      <main className='h-full relative bg-gradient-to-t to-gray-200 from-gray-50'>
        <Navbar transparent />
        <div className='h-full'>
          <div className='custom-shape-divider-top-1629929413'>
            <svg
              data-name='Layer 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 1200 120'
              preserveAspectRatio='none'>
              <path
                d='M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z'
                className='shape-fill'
              />
            </svg>
          </div>
          <Switch>
            <Route path='/auth/login' exact component={Login} />
            <Redirect from='/auth' to='/auth/login' />
          </Switch>
        </div>
        <div className='custom-shape-divider-bottom-1629929744'>
          <svg
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'>
            <path
              d='M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z'
              className='shape-fill'
            />
          </svg>
        </div>
        <FooterSmall absolute />
      </main>
    </>
  )
}
