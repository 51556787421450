import React, { useEffect, useState, useRef } from 'react'
import { TextInput } from 'components'

const DebounceTextInput = ({ delay = 500, defaultValue = '', onChange, ...props }) => {
  const timeoutRef = useRef()
  const [value, setValue] = useState(defaultValue)

  const setInputValue = (e) => {
    const vv = e.target.value
    setValue(vv)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout((v) => {
      onChange(v)
    }, delay, vv)
  }

  return <TextInput {...props} value={value} onChange={setInputValue} />
}


export {
  DebounceTextInput
}