import { lineSegmentLength, mean_1d, standardDeviation_1d } from './MathHelper'

const getLocationStats = (coordinates) => {
  var bounds = {
    minLat: coordinates[0][0],
    maxLat: coordinates[0][0],
    minLng: coordinates[0][1],
    maxLng: coordinates[0][1],
  }
  var center = {
    lat: coordinates[0][0],
    lng: coordinates[0][1],
  }

  for (let i = 1; i < coordinates.length; i++) {
    let currentCoordinate = coordinates[i]

    center['lat'] += currentCoordinate[0]
    center['lng'] += currentCoordinate[1]

    if (bounds['minLat'] >= currentCoordinate[0]) {
      bounds['minLat'] = currentCoordinate[0]
    } else if (bounds['maxLat'] <= currentCoordinate[0]) {
      bounds['maxLat'] = currentCoordinate[0]
    }
    if (bounds['minLng'] >= currentCoordinate[1]) {
      bounds['minLng'] = currentCoordinate[1]
    } else if (bounds['maxLng'] <= currentCoordinate[1]) {
      bounds['maxLng'] = currentCoordinate[1]
    }
  }

  center['lat'] = center['lat'] / coordinates.length
  center['lng'] = center['lng'] / coordinates.length

  return {
    bounds: bounds,
    center: center,
  }
}

const getLocationSnaps = (locations, snaps, overwrite = false) => {
  if (snaps && snaps.length > 0) {
    for (let i = 0; i < snaps.length; i++) {
      let currentSnap = snaps[i]
      if (currentSnap.location && overwrite === false) continue

      for (let j = 0; j < locations.length; j++) {
        let currentLocation = locations[j]
        let currentLocationBounds = currentLocation.stats.bounds

        if (
          currentSnap.lat >= currentLocationBounds.minLat &&
          currentSnap.lat <= currentLocationBounds.maxLat &&
          currentSnap.lng >= currentLocationBounds.minLng &&
          currentSnap.lng <= currentLocationBounds.maxLng
        ) {
          if (isSnapInLocation(i, j, currentLocation, currentSnap)) {
            currentSnap['location'] = currentLocation.id
            break
          }
        }

        currentSnap['location'] = 'none'
      }
    }
    return snaps
  }
}

const isSnapInLocation = (i, j, location, snap) => {
  return true
}

const getSnapClusters = (snaps) => {
  var sortedSnaps = snaps.sort(function (a, b) {
    return a.distanceFromCenter - b.distanceFromCenter
  })
  var clusterIndex = 0
  var clusters = [createClusterFromSnap(sortedSnaps[0])]

  // cluster by distance
  if (sortedSnaps.length > 1) {
    for (let i = 1; i < sortedSnaps.length; i++) {
      let currentSnap = sortedSnaps[i]

      if (
        Math.abs(sortedSnaps[i - 1].distanceFromCenter - currentSnap.distanceFromCenter) < 0.001
      ) {
        let currentCluster = clusters[clusterIndex]

        currentCluster.count += 1
        currentCluster.snaps.push(currentSnap)
        currentCluster.distanceFromCenter.total += currentSnap.distanceFromCenter
      } else {
        clusters.push(createClusterFromSnap(currentSnap))
        clusterIndex += 1
      }
    }
  }

  //  remove clusters with count 1
  var response = { clusters: [], snaps: [] }
  for (let i = 0; i < clusters.length; i++) {
    if (clusters[i].count <= 1) {
      response.snaps.push(...clusters[i].snaps)
    } else {
      let currentCluster = clusters[i]
      var clusterStats = getSnapStats(currentCluster.snaps)

      currentCluster.center = clusterStats.center
      currentCluster.lineSegmentStats = clusterStats.lineSegmentStats
      currentCluster.distanceFromCenter.average =
        currentCluster.distanceFromCenter.total / currentCluster.count

      response.clusters.push(currentCluster)
    }
  }

  //  return clusters, and non-clustered snaps
  return response
}

const getSnapStats = (snaps, removeOutliers = false) => {
  var center = { lat: snaps[0]['lat'], lng: snaps[0]['lng'] }
  if (snaps.length > 1) {
    for (let i = 1; i < snaps.length; i++) {
      center['lat'] += snaps[i].lat
      center['lng'] += snaps[i].lng
    }

    center['lat'] = center['lat'] / snaps.length
    center['lng'] = center['lng'] / snaps.length
  }

  var distanceFromCenter = []
  for (let i = 0; i < snaps.length; i++) {
    distanceFromCenter.push(
      lineSegmentLength(
        { x: snaps[i].lat, y: snaps[i].lng },
        { x: center['lat'], y: center['lng'] }
      )
    )
    snaps[i]['distanceFromCenter'] = distanceFromCenter[i]
  }

  var mean = mean_1d(distanceFromCenter)
  var standardDeviation = standardDeviation_1d(distanceFromCenter, mean)
  if (removeOutliers) {
    for (let i = 0; i < snaps.length; i++) {
      if (
        distanceFromCenter[i] > mean + standardDeviation ||
        distanceFromCenter[i] < mean - standardDeviation
      ) {
        center['lat'] -= snaps[i].lat / snaps.length
        center['lng'] -= snaps[i].lng / snaps.length
      }
    }
  }

  return {
    center: center,
    lineSegmentStats: {
      mean: mean,
      minimum: Math.min(...distanceFromCenter),
      maxiumum: Math.max(...distanceFromCenter),
      standardDeviation: standardDeviation,
    },
  }
}

const createClusterFromSnap = (snap) => {
  return {
    center: {
      lat: snap.lat,
      lng: snap.lng,
    },
    count: 1,
    distanceFromCenter: {
      average: snap.distanceFromCenter,
      total: snap.distanceFromCenter,
    },
    snaps: [snap],
  }
}

export { getLocationSnaps, getLocationStats, getSnapClusters, getSnapStats }
