import { useState, useEffect } from 'react'

const Marker = ({ onClick, onDragEnd, ...options }) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker())
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  useEffect(() => {
    if (marker) {
      ;['click', 'dragend'].forEach((eventName) =>
        window.google.maps.event.clearListeners(marker, eventName)
      )
      if (onClick) {
        marker.addListener('click', onClick)
      }

      if (onDragEnd) {
        marker.addListener('dragend', onDragEnd)
      }
    }
  }, [marker, onClick, onDragEnd])

  return null
}

export default Marker
