import { useCallback, useEffect, useState } from 'react'
import { Collections } from '../config/Constants'
import firebase from '../lib/firebase'
import { useAuth } from './AuthContext'

const useEmployees = () => {
  //  --- Variables ---
  const { userMetadata } = useAuth()
  const [faces, setFaces] = useState([])

  //  --- Setup ---
  useEffect(() => {
    if (userMetadata == null) return

    firebase
      .firestore()
      .collection(Collections.FACES)
      .where('orgId', '==', userMetadata.orgId)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) return

        setFaces(
          querySnapshot.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
            }
          })
        )
      }, console.error)
  }, [userMetadata])

  const updateEmployee = useCallback((emp) => {
    const { id, ...updates } = emp
    console.log('Updating employee', id, updates)
    return firebase.firestore().collection('faces').doc(id).set(updates, { merge: true })
  }, [])

  //  --- Response ---
  return { faces, updateEmployee }
}

export { useEmployees }
