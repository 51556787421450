import React from 'react'

const SnapImage = ({ url }) => (
  <div
    className={`w-28 h-28 my-3 rounded-full bg-gray-100 bg-cover bg-center bg-no-repeat items-center justify-center flex flex-col text-indigo-600 ${
      url === '' ? 'animate-pulse' : ''
    }`}
    style={{
      backgroundImage: `url('${url}')`,
    }}>
    {url === undefined ? 'No image' : url === '' ? '...' : null}
  </div>
)

export { SnapImage }
