import React from 'react'
import { UserDropdown } from '../'

export default function Navbar() {
  return (
    <nav className='w-full bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center py-4'>
      <div className='w-full mx-auto items-center flex justify-end'>
        <UserDropdown />
      </div>
    </nav>
  )
}
