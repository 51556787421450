import React from 'react'
import './Card.css'

const SmallCard = ({ className, ...props }) => {
  return (
    <div className={`w-full lg:w-6/12 ${className || ''}`}>
      <div className='shadow-lg '>
        <div className='p-4 rounded-md bg-white'>{props.children}</div>
      </div>
    </div>
  )
}

export default SmallCard
