import React, {
  forwardRef,
  useLayoutEffect,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as Google from './Google'
import { haversineDistance } from './Google/utils'

const LocationMap = forwardRef(({ editable, defaultCenter, location }, ref) => {
  const [locations, setLocations] = useState([])

  useImperativeHandle(ref, () => ({
    getCoordinates: () => {
      return locations.map((l) => {
        return [
          typeof l.lat === 'function' ? l.lat() : l.lat,
          typeof l.lng === 'function' ? l.lng() : l.lng,
        ]
      })
    },
  }))

  useEffect(() => {
    const coords = location.coordinates.map((coor) => ({ lat: coor[0], lng: coor[1] }))
    setLocations(coords)
  }, [location])

  const onDragMarker = (e, idx) => {
    let cloned = [...locations]
    cloned[idx] = e.latLng
    setLocations(cloned)
  }

  const onClickMarker = (e, idx) => {
    setLocations((l) => {
      let cloned = [...l]
      cloned.splice(idx, 1)
      return cloned
    })
  }

  const onClickMap = (e) => {
    setLocations((c) => [...c, e.latLng])
  }

  const onClickPolyline = (e) => {
    // console.log(e.edge, e.path, e.vertex, e)
    setLocations((c) => [...c, e.latLng])
  }

  const path = useMemo(() => {
    return location.coordinates.map((coor) => ({ lat: coor[0], lng: coor[1] }))
  }, [location])

  //  --- Response ---
  if (!location) return null
  return (
    <div className='flex flex-col space-y-4'>
      <div className='overflow-hidden rounded-lg'>
        <Google.Container
          style={{ height: 600, width: '100%' }}
          onClick={editable ? onClickMap : undefined}>
          <Google.Bounds locations={path} padding={20} />
          <Google.Polygon
            path={locations}
            strokeColor='#6265ee'
            fillColor='#6265ee'
            strokeWeight={1.5}
            onClick={onClickPolyline}
          />
          {editable
            ? locations.map((latLng, idx) => (
                <Google.Marker
                  key={idx}
                  position={latLng}
                  draggable={true}
                  onDragEnd={(e) => onDragMarker(e, idx)}
                  onClick={(e) => onClickMarker(e, idx)}
                />
              ))
            : null}
        </Google.Container>
      </div>
      {/* <div className='flex flex-col'>
        <p className='my-auto text-2xl pb-2'>DETAILS</p>
        <div className='flex flex-row'>
          <div className='w-1/3 space-y-1.5'>
            <p>ID:</p>
            <p>Created At:</p>
            <p>Created By:</p>
            <p>Updated At:</p>
          </div>
          <div className='w-2/3 space-y-2'>
            <p>{location.id}</p>
            <p>{toLocalTime(location.createdAt)}</p>
            <p>{location.createdBy}</p>
            <p>{location.updatedAt}</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <p className='my-auto text-2xl pb-2'>STATS</p>
        <div className='flex flex-row space-x-4'>
          <div className='w-1/3 space-y-1.5'>
            <p>Center:</p>
          </div>
          <div className='w-2/3 space-y-2'>
            <p>{`[${location.stats.center.lat.toPrecision(
              4
            )},  ${location.stats.center.lng.toPrecision(4)}]`}</p>
          </div>
        </div>
      </div> */}
    </div>
  )
})

export default LocationMap
