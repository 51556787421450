import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

const color = (variant) => {
  return variant === 'primary'
    ? 'text-white bg-indigo-500 hover:bg-indigo-600'
    : variant === 'secondary'
    ? 'text-indigo-500 bg-gray-50 hover:bg-gray-100'
    : 'text-white bg-gray-300 hover:bg-gray-400'
}

const MenuDropdown = ({ items, children, variant = 'default', disabled = false }) => {
  return (
    <Menu as='Fragment' className='relative inline-block text-left'>
      <Menu.Button
        disabled={disabled}
        className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium ${color(
          variant
        )} rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
        {children}
        <ChevronDownIcon className='ml-1 h-4 w-4 mt-0.5' />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <Menu.Items className='absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
          {items.map((item, itemIdx) => (
            <div className='p-1' key={itemIdx}>
              <Menu.Item disabled={item.disabled}>
                {({ active }) => (
                  <button
                    onClick={item.onClick}
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex hover:bg-indigo-400  rounded-md items-center w-full px-2 py-2 text-sm`}>
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default MenuDropdown
