import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button } from 'components'

const TableDropdown = ({ data, filename }) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false)
  return (
    <Button>
      <CSVLink
        onClick={() => setDropdownVisibility(!dropdownVisibility)}
        data={data}
        filename={filename || 'snapwage-table-export.csv'}
        className='py-1'
        target='_blank'>
        Export CSV
      </CSVLink>
    </Button>
  )
}

export default TableDropdown
