import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react'
import firebase from '../lib/firebase'
import { Collections } from '../config/Constants'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export default function AuthenticationProvider({ children }) {
  const [isInitializing, setIsInitializing] = useState(true)
  const [user, setUser] = useState(null)
  const [userMetadata, setUserMetadata] = useState(null)

  useEffect(() => {
    const subscription = firebase.auth().onAuthStateChanged((user) => {
      setUser(user)
      if (isInitializing) setIsInitializing(false)
    })

    return subscription
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = firebase
        .firestore()
        .collection(Collections.USERS)
        .doc(user.uid)
        .onSnapshot((userDocument) => {
          const metadata = userDocument.data()

          if (!metadata) return

          setUserMetadata(metadata)
        }, console.error)

      return () => unsubscribe()
    }
  }, [user])

  const login = useCallback(
    ({ email, password }) => firebase.auth().signInWithEmailAndPassword(email, password),
    []
  )

  const logout = useCallback(() => {
    firebase.auth().signOut()
  }, [])

  const value = useMemo(
    () => ({
      isAuthenticated: !!user,
      user,
      userMetadata,
      login,
      logout,
    }),
    [user, userMetadata, login, logout]
  )

  if (isInitializing) return null
  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>
}
