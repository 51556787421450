import { useCallback, useEffect, useState } from 'react'
import { Collections } from '../config/Constants'
import { useAuth } from './AuthContext'
import firebase from '../lib/firebase'
import { getLocationStats } from 'helpers/BackendHelper'
import { jsonToArray } from 'helpers/ApplicationHelper'
import { arrayToJson } from 'helpers/ApplicationHelper'

const useLocations = () => {
  //  --- Variables ---
  const { userMetadata: user } = useAuth()
  const [locations, setLocations] = useState([])

  //  --- Functionality ---
  const createCallback = useCallback(
    (coordinates, name) => {
      if (!user || !coordinates || !name) return Promise.resolve()

      return firebase
        .firestore()
        .collection(Collections.LOCATIONS)
        .add({
          coordinates: arrayToJson(coordinates),
          createdBy: user.userId,
          createdAt: Date.now(),
          name: name,
          orgId: user.orgId,
          stats: getLocationStats(coordinates),
        })
    },
    [user]
  )

  useEffect(() => {
    if (user == null) return

    firebase
      .firestore()
      .collection(Collections.LOCATIONS)
      .where('orgId', '==', user.orgId)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) return

        setLocations(
          querySnapshot.docs.map((document) => {
            const { coordinates, ...locationData } = document.data()
            return { ...locationData, coordinates: jsonToArray(coordinates), id: document.id }
          })
        )
      }, console.error)
  }, [user])

  const updateCallback = useCallback(
    (body, id) => {
      if (!user || !body || !id) return

      return firebase
        .firestore()
        .collection(Collections.LOCATIONS)
        .doc(id)
        .set({ ...body, updatedAt: Date.now() })
    },
    [user]
  )

  //  --- Response ---
  return {
    locations,
    saveLocation: createCallback,
    updateLocation: updateCallback,
  }
}

export default useLocations
