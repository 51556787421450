import { useCallback, useMemo } from 'react'
import { useAuth, useSnapsAndSessions } from 'context'
import firebase from '../lib/firebase'
import { useLocations } from 'context'
import pointInPolygon from 'point-in-polygon'

const useSessions = (from, to, approved = false) => {
  const { userMetadata } = useAuth()
  const { snaps, sessions: rawSessions } = useSnapsAndSessions(userMetadata, from, to, approved)
  const { locations } = useLocations()

  const sessions = useMemo(() => {
    if (snaps.length < 1) return []
    return rawSessions.map((session) => {
      const startSnap = snaps.find((s) => s.id === session.startSnap)
      if (!startSnap) {
        return {
          id: session.id,
          fullName: session.fullName,
          status: session.status,
          faceId: session.faceId,
          startTime: session.startTime,
          endTime: session.endTime || null,
          duration: !!session.endTime
            ? Math.abs(session.endTime - session.startTime) / 3600000
            : null,
        }
      }

      const geos = locations
        .filter((geo) => pointInPolygon([startSnap.lat, startSnap.lng], geo.coordinates))
        .map((g) => g.name)
        .join(', ')

      return {
        id: session.id,
        fullName: session.fullName,
        latitude: startSnap.lat,
        longitude: startSnap.lng,
        faceId: startSnap.faceId,
        status: session.status,
        audit: session.audit,
        startSnap: session.startSnap,
        closeSnap: session.closeSnap,
        duration: !!session.endTime
          ? Math.abs(session.endTime - session.startTime) / 3600000
          : null,
        startTime: session.startTime,
        endTime: session.endTime || null,
        geozone: geos,
      }
    })
  }, [snaps, rawSessions, locations])

  const bulkUpdate = async (selectedSessions, changes) => {
    const batch = firebase.firestore().batch()
    for (let session of selectedSessions) {
      const doc = firebase.firestore().collection('sessions').doc(session.id)
      batch.update(doc, changes)
    }
    await batch.commit()
  }

  const updateSession = useCallback((session) => {
    const { id, ...updates } = session
    return firebase.firestore().collection('sessions').doc(id).set(updates, { merge: true })
  }, [])

  return { sessions, updateSession, bulkUpdate }
}

export { useSessions }
