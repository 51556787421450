import React, { useMemo } from 'react'
import { StatsBar, Card, DashboardMap, ReactTable } from 'components'
import { useLocations } from 'context'
import dayjs from 'dayjs'
import { Dropdown } from 'components'
import { useTranslation } from 'react-i18next'
import { Duration } from 'components'
import { useSessions } from 'hooks'
import { useQueryParam } from 'hooks'
import { useEmployees } from 'context'

const getDatesForRange = (range) => {
  const dt = {
    today: () => ({
      from: dayjs().startOf('date').valueOf(),
      to: dayjs().valueOf(),
    }),
    thisWeek: () => ({
      from: dayjs().startOf('week').valueOf(),
      to: dayjs().valueOf(),
    }),
    lastWeek: () => ({
      from: dayjs().startOf('week').subtract(1, 'week').valueOf(),
      to: dayjs().startOf('week').valueOf(),
    }),
    thisMonth: () => ({
      from: dayjs().startOf('month').valueOf(),
      to: dayjs().valueOf(),
    }),
    lastMonth: () => ({
      from: dayjs().startOf('month').subtract(1, 'month').valueOf(),
      to: dayjs().startOf('month').valueOf(),
    }),
  }
  return dt[range]()
}

export default function Dashboard() {
  //  --- Variables ---
  const [range, setRange] = useQueryParam('range', 'today')
  const { from, to } = useMemo(() => getDatesForRange(range), [range])

  const { sessions } = useSessions(from, to)
  const { t } = useTranslation()
  const { locations } = useLocations()
  const { faces } = useEmployees()

  // const activeSessions = useMemo(() => {
  //   return sessions.filter((s) => s.status === 'open')
  // }, [sessions])

  //  --- Response ---
  return (
    <>
      <Dropdown
        options={['today', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth']}
        getLabel={(item) => t(`application:period.${item}`)}
        value={range}
        setValue={setRange}
      />
      <div className='mt-4'>
        <StatsBar employees={faces} sessions={sessions} />
      </div>
      <div className='flex flex-wrap flex-col sm:flex-row sm:space-x-4 space-x-0'>
        <div className='flex-grow'>
          <DashboardMap
            // defaultCenter={{ lat: -29.91275, lng: 23.998791 }}
            sessions={sessions}
            locations={locations}
          />
        </div>
        <div>
          {sessions ? (
            <Card>
              <ReactTable
                columns={columns}
                data={sessions}
                initialSort={{ id: 'duration', desc: false }}
              />
            </Card>
          ) : null}
        </div>
      </div>
    </>
  )
}

const columns = [
  {
    i18n: 'snapshots:name',
    accessor: 'fullName',
    Header: 'Name',
    width: 150,
  },
  {
    i18n: 'application:duration',
    accessor: 'duration',
    Header: 'Duration',
    width: 100,
    Cell: (props) => (
      <span className={!!props.row.original.endTime ? 'text-indigo-500' : null}>
        <Duration
          from={props.row.original.startTime}
          to={!!props.row.original.endTime ? props.row.original.endTime : undefined}
        />
      </span>
    ),
  },
  {
    i18n: 'application:time',
    accessor: 'startTime',
    Header: 'Time',
    width: 160,
    Cell: (props) => dayjs(props.row.original.startTime).format('ddd, DD MMM'),
  },
]
