const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(utc)
dayjs.extend(relativeTime)

const getDate = (date) => {
  switch (date) {
    case 'yesterday':
      return dayjs().subtract(1, 'days').format('YYYY-MM-DD')

    case 'thisWeek':
      return dayjs().startOf('week').format('YYYY-MM-DD')

    case 'lastWeek':
      return dayjs().startOf('week').subtract(1, 'week').format('YYYY-MM-DD')

    case 'lastMonth':
      return dayjs().subtract(1, 'month').format('YYYY-MM-DD')

    case 'yearStart':
      return dayjs().startOf('year').format('YYYY-MM-DD')

    default:
      return dayjs().format('YYYY-MM-DD')
  }
}

const howManyDaysAgo = (date) => {
  return dayjs().diff(dayjs(date), 'days', true)
}

const toLocalTime = (dateTime) => {
  return dayjs(dateTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
}

export { getDate, howManyDaysAgo, toLocalTime }
