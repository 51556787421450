import { useState, useEffect } from 'react'

// poly = new google.maps.Polyline({
//   strokeColor: "#000000",
//   strokeOpacity: 1.0,
//   strokeWeight: 3,
// });
// poly.setMap(map);

const Polygon = ({ onClick, ...options }) => {
  const [polygon, setPolygon] = useState()

  useEffect(() => {
    if (!polygon) {
      setPolygon(new window.google.maps.Polygon())
    }

    // remove marker from map on unmount
    return () => {
      if (polygon) {
        polygon.setMap(null)
      }
    }
  }, [polygon])

  useEffect(() => {
    if (polygon) {
      polygon.setOptions(options)
    }
  }, [polygon, options])

  useEffect(() => {
    if (polygon) {
      ;['click'].forEach((eventName) => window.google.maps.event.clearListeners(polygon, eventName))
      if (onClick) {
        polygon.addListener('click', onClick)
      }
    }
  }, [polygon, onClick])

  return null
}

export default Polygon
