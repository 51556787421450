import React from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import Map from './Map'

const Container = ({ children, ...props }) => {
  return (
    <Wrapper apiKey={'AIzaSyD34egYnoYwFeznSnirgrMF_4PXTCsbOHA'}>
      <Map {...props} {...defaultMapProps}>
        {children}
      </Map>
    </Wrapper>
  )
}

export default Container

const defaultMapProps = {
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
}
