import { Button, TextInput } from 'components'
import React from 'react'

export default function CardSettings({ onChange, user, updateUser }) {
  const onSubmit = (e) => {
    e.preventDefault()
    updateUser({ ...user })
  }

  return (
    <>
      <div className='text-center flex justify-between'>
        <h6 className='text-gray-800 text-xl font-bold'>My account</h6>
        <Button onClick={onSubmit}>Save</Button>
      </div>
      <form onSubmit={onSubmit}>
        <hr className='mt-6 border-b-1 border-gray-400' />
        <h6 className='text-gray-500 text-sm mt-3 mb-6 font-bold uppercase'>User Information</h6>
        <div className='flex flex-wrap'>
          {/* <div className='w-full lg:w-6/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Username
              </label>
              <TextInput
                type='text'
                name='userName'
                value={user.userName}
                onChange={onChange}
              />
            </div>
          </div> */}
          <div className='w-full md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Email address
              </label>
              <TextInput
                className='bg-gray-100'
                disabled
                type='email'
                name='email'
                value={user.email}
                onChange={onChange}
              />
            </div>
          </div>
          <div className='w-full lg:w-6/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                First Name
              </label>
              <TextInput type='text' name='firstName' value={user.firstName} onChange={onChange} />
            </div>
          </div>
          <div className='w-full lg:w-6/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Last Name
              </label>
              <TextInput type='text' name='lastName' value={user.lastName} onChange={onChange} />
            </div>
          </div>
        </div>

        <hr className='mt-6 border-b-1 border-gray-400' />

        <h6 className='text-gray-500 text-sm mt-3 mb-6 font-bold uppercase'>Contact Information</h6>
        <div className='flex flex-wrap'>
          <div className='w-full lg:w-12/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Address
              </label>
              <TextInput type='text' name='address' value={user.address} onChange={onChange} />
            </div>
          </div>
          <div className='w-full lg:w-4/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                City
              </label>
              <TextInput type='text' name='city' value={user.city} onChange={onChange} />
            </div>
          </div>
          <div className='w-full lg:w-4/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Country
              </label>
              <TextInput type='text' name='country' value={user.country} onChange={onChange} />
            </div>
          </div>
          <div className='w-full lg:w-4/12 md:px-4'>
            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-gray-700 text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Postal Code
              </label>
              <TextInput
                type='text'
                name='postalCode'
                value={user.postalCode}
                onChange={onChange}
              />
            </div>
          </div>
        </div>

        <hr className='mt-6 border-b-1 border-gray-400' />
      </form>
    </>
  )
}
