export const Regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

export const Collections = {
  USERS: 'users',
  ORGANIZATIONS: 'organizations',
  SNAPS: 'snaps',
  FACES: 'faces',
  LOCATIONS: 'locations',
  MACHINES: 'machines',
}
