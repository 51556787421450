import React, { useMemo } from 'react'
import StatsCard from 'components/Cards/StatsCard.js'
import { formatNumber, getVal } from 'helpers/ApplicationHelper'

const StatsBar = ({ employees, sessions }) => {
  //  --- Setup ---

  const stats = useMemo(() => {
    let activeLocations = new Set()
    let activeEmployees = new Set()
    let total = 0
    let totalCost = 0

    const employeeMap = {}
    for (let i = 0; i < employees.length; i++) {
      employeeMap[employees[i].id] = employees[i]
    }

    for (let i = 0; i < sessions.length; i++) {
      activeEmployees.add(sessions[i].faceId)
      activeLocations.add(sessions[i].geozone)

      if (sessions[i].status === 'done') {
        total += +sessions[i].duration
        totalCost += +(sessions[i].duration * getVal(employeeMap[sessions[i].faceId], 'rate', 0))
      }
    }

    return {
      checkins: activeEmployees.size,
      activeLocations: activeLocations.size,
      totalHours: isNaN(total) ? '-' : total.toFixed(2),
      cost: formatNumber(totalCost),
    }
  }, [sessions, employees])

  //  --- response ---
  return (
    <div className='flex flex-col 2xl:flex-row mb-10 space-y-5 2xl:space-x-5 2xl:space-y-0'>
      <div className='flex flex-col lg:flex-row lg:space-x-5 space-y-5 lg:space-y-0 2xl:w-6/12'>
        <StatsCard
          statSubtitle='Total Sessions'
          statTitle={`${stats.checkins}`}
          // statArrow='up'
          // statPercent='3.48'
          statPercentColor='text-green-500'
          // statDescripiron='At the moment'
          statIconName='badge'
          statIconColor='bg-red-500'
        />
        <StatsCard
          statSubtitle='Tracked Locations'
          statTitle={`${stats.activeLocations}`}
          // statArrow='down'
          // statPercent='3.48'
          statPercentColor='text-red-500'
          // statDescripiron='At the moment'
          statIconName='location'
          statIconColor='bg-green-500'
        />
      </div>
      <div className='flex flex-col lg:flex-row lg:space-x-5 space-y-5 lg:space-y-0 2xl:w-6/12'>
        <StatsCard
          statSubtitle='TOTAL HOURS'
          statTitle={`${stats.totalHours}`}
          // statArrow='down'
          // statPercent='1.10'
          statPercentColor='text-orange-500'
          statDescripiron='This week'
          statIconName='clock'
          statIconColor='bg-pink-500'
        />
        <StatsCard
          statSubtitle='Estimated Cost'
          statTitle={`${stats.cost}`}
          // statArrow='up'
          // statPercent='12'
          statPercentColor='text-green-500'
          statDescripiron='Last 7 days'
          statIconName='mobile'
          statIconColor='bg-indigo-500'
        />
      </div>
    </div>
  )
}

export default StatsBar
