import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Dashboard, Faces, Locations, Settings, Snapshots, Machines, Reports, Users } from 'views'

// components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'

export default function Admin() {
  return (
    <div className='bg-gray-200 flex flex-col md:flex-row'>
      <Sidebar />
      <div className='w-full md:overflow-y-auto md:h-screen'>
        <div className='flex flex-col justify-between min-h-screen w-full mx-auto px-5 md:px-10'>
          <AdminNavbar />
          <Switch>
            <Route path='/admin/dashboard' exact component={Dashboard} />
            <Route path='/admin/faces' exact component={Faces} />
            <Route path='/admin/locations' component={Locations} />
            <Route path='/admin/users' component={Users} />
            <Route path='/admin/machines' component={Machines} />
            <Route path='/admin/settings' exact component={Settings} />
            <Route path='/admin/snapshots' exact component={Snapshots} />
            <Route path='/admin/reports' exact component={Reports} />
            <Redirect from='/admin' to='/admin/dashboard' />
          </Switch>
          <div className='flex-grow' />
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </div>
  )
}
