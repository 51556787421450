import React, { useMemo } from 'react'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
import MenuDropdown from './MenuDropdown'

const UserDropdown = () => {
  //  --- Variables ---
  const { t } = useTranslation()
  const { logout, user, userMetadata } = useAuth()

  const profileName = useMemo(() => {
    if (userMetadata && (userMetadata.firstName || userMetadata.lastName)) {
      return `${userMetadata.firstName} ${userMetadata.lastName}`.trim()
    } else {
      return user.email
    }
  }, [user, userMetadata])

  // if (!userMetadata) return null
  //  --- Response ---
  return (
    <MenuDropdown
      items={[
        {
          label: t('application:logout'),
          onClick: () => {
            console.log('On logout click')
            logout()
          },
        },
      ]}>
      {profileName}
    </MenuDropdown>
  )
}

export default UserDropdown
