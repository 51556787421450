import { useEffect, useRef } from 'react'
import { createCustomEqual } from 'fast-equals'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'

// [END maps_react_map_marker_component]
const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b))
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b)
})

function useDeepCompareMemoize(value) {
  const ref = useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback])
}

function haversineDistance(position1, position2) {
  var R = 3958.8 // Radius of the Earth in miles
  var rlat1 = position1.lat() * (Math.PI / 180) // Convert degrees to radians
  var rlat2 = position2.lat() * (Math.PI / 180) // Convert degrees to radians
  var difflat = rlat2 - rlat1 // Radian difference (latitudes)
  var difflon = (position2.lng() - position1.lng()) * (Math.PI / 180) // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    )
  return d
}

export { useDeepCompareEffectForMaps, haversineDistance }
