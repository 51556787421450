import React from 'react'

const Card = ({ className, innerPadding, ...props }) => {
  return (
    <div className={`bg-white rounded-lg md:shadow-lg ${className || ''}`}>
      <div className={`${innerPadding || 'px-8 py-7'}`}>{props.children}</div>
    </div>
  )
}

export default Card
