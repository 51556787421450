import React from 'react'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import BaseDatePicker from 'react-datepicker'

const DatePicker = ({ value, onSubmit, onClose, ...props }) => {
  return (
    <BaseDatePicker
      selected={value}
      disabledKeyboardNavigation
      startOpen={false}
      dateFormat='dd-MM-yyyy'
      onChange={onSubmit}
      onClickOutside={onClose}
      nextMonthButtonLabel={<ChevronRightIcon className='w-5 h-5' />}
      previousMonthButtonLabel={<ChevronLeftIcon className='w-5 h-5' />}
      {...props}
    />
  )
}

export { DatePicker }
