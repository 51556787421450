import React from 'react'
import { Card, ReactTable } from 'components'
import { useMachines } from 'context'

export default function Machines() {
  const { machines } = useMachines()

  return (
    <Card>
      {machines.length ? (
        <ReactTable
          columns={columns}
          data={machines}
          initialSort={{ id: 'fullName', desc: true }}
          showDownload={true}
          title='Machines'
        />
      ) : null}
    </Card>
  )
}

const columns = [
  {
    i18n: 'faces:name',
    accessor: 'fullName',
    Header: 'Name',
  },
  {
    i18n: 'faces:status',
    accessor: 'status',
    Header: 'Status',
  },
  {
    i18n: 'faces:status',
    accessor: 'lolo',
    Header: 'Status',
    Cell: () => {
      return <div>Awe</div>
    },
  },
  // {
  //   i18n: 'faces:imageId',
  //   accessor: 'imageId',
  //   Header: 'Image ID',
  // },
]
