import React from 'react'

export default function FooterSmall(props) {
  return (
    <>
      <footer className={(props.absolute ? 'absolute w-full bottom-0 ' : 'relative') + ' pb-6'}>
        <div className='container mx-auto px-4'>
          <hr className='mb-6 border-b-1 border-gray-300' />
          <div className='flex flex-wrap items-center md:justify-between justify-center'>
            <div className='w-full md:w-4/12 px-4'>
              <div className='text-sm text-gray-600 font-semibold py-1 text-center md:text-left'>
                <a
                  className='text-gray-600 hover:text-gray-400 text-sm font-semibold py-1'
                  href='https://redbear.studio/'
                  target='_blank'
                  rel='noreferrer'>
                  A Redbear.Studio Product
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
