import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import firebaseConfig from '../config/firebase.json'

if (!firebaseConfig) throw new Error('Firebase config required at config/firebase.json')

firebase.initializeApp(firebaseConfig)

export default firebase
