import React, { useEffect, useState } from 'react'
import { Regex } from 'config/Constants'
import { useAuth } from 'context'
import { toast } from 'react-hot-toast'
import { TextInput } from 'components'
import { Button } from 'components'

export default function Login() {
  //  --- Variables ---
  const { login } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)

  //  --- Setup ---
  useEffect(() => {
    const isValidEmail = !!email?.length && Regex.email.test(email)
    const isValidPassword = !!password?.length

    setCanSubmit(isValidEmail && isValidPassword)
  }, [email, password])

  const onLogin = (e) => {
    e.preventDefault()

    login({ email, password })
      .then(() => {
        window.location.href = '/admin'
      })
      .catch(() => {
        toast.error('Bad Credentials, try again.')
      })
  }

  //  --- Response ---
  return (
    <div className='flex h-screen'>
      <div
        className='my-auto mx-auto w-11/12 md:w-6/12 lg:w-5/12 xl:w-4/12'
        style={{ maxWidth: 400 }}>
        <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-50 border-0'>
          <div className='flex-auto p-6 py-8 md:p-10'>
            <div className='text-gray-800 text-center mt-3 mb-6 font-bold text-xl'>Sign in</div>
            <form className='space-y-3' onSubmit={onLogin}>
              <div>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Email
                </label>
                <TextInput
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Password
                </label>
                <TextInput
                  autoComplete='off'
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <br />
              {/* <div>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    id='customCheckLogin'
                    type='checkbox'
                    className='form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150'
                  />
                  <span className='ml-2 text-sm font-semibold text-gray-700'>Remember me</span>
                </label>
              </div> */}

              <div className='text-center text-white text-sm font-bold uppercase mt-6'>
                <Button
                  type='submit'
                  className='w-full text-center justify-center py-3'
                  disabled={!canSubmit}>
                  Sign In
                </Button>
              </div>
            </form>
          </div>
        </div>
        {/* <div className='mt-6 relative'>
          <a href='#ToDo' onClick={(e) => e.preventDefault()} className='text-gray-700'>
            Forgot password?
          </a>
        </div> */}
      </div>
    </div>
  )
}
