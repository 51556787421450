import React from 'react'

const Button = ({ children, className, onClick, secondary = false, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        secondary
          ? 'text-indigo-500 bg-gray-50 hover:bg-gray-200'
          : 'text-white bg-indigo-500 hover:bg-indigo-600 hover:shadow-md'
      } items-center flex focus:outline-none font-bold outline-none px-5 py-2 rounded-lg space-x-2 text-xs uppercase ${
        className || ''
      }`}>
      {children}
    </button>
  )
}

export default Button
