const jsonToArray = (jsonObject) => {
  var response = []

  for (let i = 0; i < jsonObject.count; i++) {
    response.push(jsonObject[i])
  }

  return response
}

const arrayToJson = (arrayObject) => {
  var response = {
    count: arrayObject.length,
  }

  for (let i = 0; i < arrayObject.length; i++) {
    response[`${i}`] = arrayObject[i]
  }

  return response
}

const filterData = (data, query) => {
  if (!query) return data

  return data.filter((dataInstance) => {
    let values = Object.values(dataInstance)

    for (let i = 0; i < values.length; i++) {
      if (
        JSON.stringify(values[i] || '')
          .toLowerCase()
          .includes(query.toLowerCase())
      )
        return true
    }

    return false
  })
}

const formatNumber = (number) =>
  new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(number)

const getVal = (obj, key, defaultValue) => {
  return !!obj && obj[key] ? obj[key] : defaultValue
}

export { formatNumber, getVal, filterData, arrayToJson, jsonToArray }
