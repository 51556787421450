import application from './application.json'
import faces from './faces.json'
import map from './map.json'
import snapshots from './snapshots.json'

const en = {
  application,
  faces,
  map,
  snapshots,
}

export default en
