import React, { useEffect, useMemo, useState } from 'react'
import { Card, ReactTable } from 'components'
import { useQueryParam } from 'hooks'
import { ExclamationIcon } from '@heroicons/react/solid'
import { getVal, formatNumber } from 'helpers/ApplicationHelper'
import { useSessions } from 'hooks'
import { toast } from 'react-hot-toast'
import dayjs from 'dayjs'
import MenuDropdown from 'components/Dropdowns/MenuDropdown'
import { Modal } from 'components/Modal'
import { TextInput } from 'components'
import { Button } from 'components'
import { DatePicker } from 'components/Inputs/DatePicker'
import { useAuth } from 'context'
import firebase from '../../lib/firebase'
import { SnapImage } from './Timesheets/components/SnapImage'
import { useEmployees } from 'context'

const EditSessionForm = ({ session, onClose, onSubmit }) => {
  const [startTime, setStartTime] = useState(dayjs(session.startTime).format('HH:mm'))
  const [endTime, setEndTime] = useState(dayjs(session.endTime).format('HH:mm'))
  const [startDate, setStartDate] = useState(session.startTime)
  const [endDate, setEndDate] = useState(session.endTime)

  const duration = useMemo(() => {
    const start = dayjs(startDate)
      .set('hours', startTime.split(':')[0])
      .set('minutes', startTime.split(':')[1])
      .set('second', 0)
    const end = dayjs(endDate)
      .set('hours', endTime.split(':')[0])
      .set('minutes', endTime.split(':')[1])
      .set('second', 0)

    return (Math.abs(end.toDate().getTime() - start.toDate().getTime()) / 3600000).toFixed(2)
  }, [startTime, startDate, endTime, endDate])

  const submit = () => {
    const start = dayjs(startDate)
      .set('hours', startTime.split(':')[0])
      .set('minutes', startTime.split(':')[1])
      .set('second', 0)
    const end = dayjs(endDate)
      .set('hours', endTime.split(':')[0])
      .set('minutes', endTime.split(':')[1])
      .set('second', 0)

    onSubmit({
      startTime: start.toDate().getTime(),
      endTime: end.toDate().getTime(),
    })
  }

  return (
    <>
      <div className='flex flex-col justify-between mt-3 gap-4'>
        <div>
          <span className='flex flex-col justify-center w-full'>Start:</span>
          <div className='flex flex-row justify-between gap-3'>
            {session ? (
              <>
                <TextInput
                  type='time'
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <DatePicker
                  value={startDate}
                  onSubmit={setStartDate}
                  name='startTime'
                  maxDate={endDate}
                />
              </>
            ) : null}
          </div>
        </div>
        <div>
          <span className='flex flex-col justify-center w-full'>End:</span>
          <div className='flex flex-row justify-between gap-3'>
            {session ? (
              <>
                <TextInput
                  type='time'
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
                <DatePicker
                  minDate={startDate}
                  value={endDate}
                  onSubmit={setEndDate}
                  name='endTime'
                />
              </>
            ) : null}
          </div>
        </div>
        <div className='flex flex-row font-semibold'>
          <div>Duration:</div>
          <div className='text-indigo-500 ml-3'>{duration}</div>
        </div>
      </div>
      <div className='flex flex-row justify-end space-x-2 mt-3'>
        <Button secondary={true} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={submit}>Save</Button>
      </div>
    </>
  )
}

const DetailForm = ({ session, onClose }) => {
  const [urls, setUrls] = useState({ start: '', close: '' })

  useEffect(() => {
    firebase
      .functions()
      .httpsCallable('sessionImages')({ session })
      .then(({ data }) => setUrls(data.urls))
  }, [session])

  return (
    <div className='flex flex-col'>
      {session.audit ? (
        <div className='text-indigo-500 font-semibold my-3'>
          {session.audit.author_email} {session.audit.message}
        </div>
      ) : null}
      <div className='flex flex-row justify-around mt-4 space-x-4'>
        <SnapImage url={urls.start} />
        <div className='w-1 bg-gray-200' />
        <SnapImage url={urls.close} />
      </div>
      <div className='flex flex-row justify-end space-x-2 mt-3'>
        <Button secondary={true} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default function Tables() {
  const [query, setQuery] = useQueryParam('filter')

  const [selected, setSelected] = useState()
  const [detail, setDetailSelected] = useState()
  const [showApprovePrompt, setShowApprovePrompt] = useState(false)

  const { user } = useAuth()
  const { faces } = useEmployees()
  const { sessions, updateSession, bulkUpdate } = useSessions(undefined, undefined, false)

  const filteredData = useMemo(() => {
    const employeeMap = {}
    for (let i = 0; i < faces.length; i++) {
      employeeMap[faces[i].id] = faces[i]
    }

    const filtered = sessions.filter((sesh) => sesh.status === 'done')
    const total = filtered.reduce((a, b) => a + b.duration, 0).toFixed(2)
    const cost = filtered
      .reduce((a, b) => a + b.duration * getVal(employeeMap[b.faceId], 'rate', 0), 0)
      .toFixed(2)

    return {
      data: filtered,
      cost: formatNumber(cost),
      total,
    }
  }, [sessions, faces])

  const onEditSession = (row) => {
    setSelected({
      ...row,
      startTime: new Date(row.startTime),
      endTime: new Date(row.endTime),
    })
  }

  const onUpdateSession = async (times) => {
    const audit = {
      author: user.uid,
      author_email: user.email,
      message: 'set the times manually',
      event: 'Updated time',
    }
    await toast.promise(updateSession({ id: selected.id, ...times, audit }), {
      success: 'Saved changes',
      error: 'Could not update session',
    })
    setSelected()
  }

  const cancelEdit = () => {
    setSelected()
  }

  const onApprove = async () => {
    setShowApprovePrompt(false)
    await toast.promise(bulkUpdate(filteredData.data, { approved: true }), {
      loading: 'Approving sessions',
      success: 'Saved changes',
      error: 'Could not update session',
    })
  }

  const cancelApprove = () => {
    setShowApprovePrompt(false)
  }

  const onDetailSelect = (session) => {
    setDetailSelected(session)
  }

  const cancelDetail = () => {
    setDetailSelected()
  }

  return (
    <Card>
      <div className='flex flex-row space-x-2 justify-between'>
        <div className='flex flex-row flex-wrap space-x-10'>
          <div className='font-bold text-2xl'>
            Total hours: <span className='text-indigo-700'>{filteredData.total}</span>
          </div>
          <div className='font-bold text-2xl pb-6'>
            Total cost: <span className='text-indigo-700'>{filteredData.cost}</span>
          </div>
        </div>
        <div>
          <Button onClick={(e) => setShowApprovePrompt(true)}>Approve Timesheets</Button>
        </div>
      </div>
      <ReactTable
        columns={columns(onEditSession)}
        data={filteredData.data}
        initialSort={{ id: 'endTime', desc: true }}
        rowOnClick={onDetailSelect}
        overrideOnClick={[6]}
      />

      <Modal isOpen={!!detail} setIsOpen={cancelDetail} title='Session details' size={'3/4'}>
        {detail ? <DetailForm session={detail} onClose={cancelDetail} /> : null}
      </Modal>

      <Modal isOpen={!!selected} setIsOpen={cancelEdit} title='Edit session' size={'1/2'}>
        {selected ? (
          <EditSessionForm session={selected} onClose={cancelEdit} onSubmit={onUpdateSession} />
        ) : null}
      </Modal>

      <Modal
        isOpen={showApprovePrompt}
        setIsOpen={cancelApprove}
        title='Approve selected timesheets'
        size={'1/2'}>
        <div className='mt-5'>
          Approving these timesheets will make them <b>unchangeable</b>, remove them from the mobile
          app and make the data available in reports.
        </div>
        <div className='flex flex-row justify-end space-x-2 mt-3'>
          <Button secondary={true} onClick={cancelApprove}>
            Cancel
          </Button>
          <Button onClick={onApprove}>Approve</Button>
        </div>
      </Modal>
    </Card>
  )
}

const columns = (onEditSession) => [
  {
    i18n: 'snapshots:name',
    accessor: 'fullName',
    Header: 'Name',
    Cell: ({ row }) => <div>{row.original.fullName}</div>,
  },
  {
    i18n: 'snapshots:startTime',
    accessor: 'startTime',
    Header: 'Start',
    Cell: ({ row }) => dayjs(row.original.startTime).format('DD-MM-YYYY HH:mm:ss'),
  },
  {
    i18n: 'snapshots:endTime',
    accessor: 'endTime',
    Header: 'End',
    Cell: ({ row }) =>
      row.original.endTime ? dayjs(row.original.endTime).format('DD-MM-YYYY HH:mm:ss') : '-',
  },
  {
    i18n: 'snapshots:duration',
    accessor: 'duration',
    Header: 'Duration',
    Cell: ({ row }) => (
      <div className='flex flex-row'>
        {row.original.duration ? row.original.duration.toFixed(2) : '-'}
        {!!row.original.audit ? (
          <ExclamationIcon className='h-4 w-4 text-yellow-400 my-auto ml-2' />
        ) : null}
      </div>
    ),
  },
  {
    i18n: 'snapshots:duration',
    accessor: 'coordinates',
    Cell: ({ row: { original } }) => (
      <a
        className='text-indigo-600'
        target='_blank'
        rel='noreferrer'
        href={`https://www.google.com/maps/place/${original.latitude},${original.longitude}`}>
        {original.latitude}, {original.longitude}
      </a>
    ),
  },
  {
    i18n: 'snapshots:location',
    accessor: 'geozone',
    Header: 'Location',
  },
  {
    i18n: 'snapshots:location',
    Header: 'Actions',
    accessor: 'actions',
    width: 100,
    Cell: ({ row: { original } }) => (
      <div>
        <MenuDropdown
          variant='secondary'
          items={[
            {
              label: 'Edit',
              disabled: !original.endTime,
              onClick: (e) => {
                e.preventDefault()
                onEditSession(original)
              },
            },
          ]}>
          Actions
        </MenuDropdown>
      </div>
    ),
  },
]

/*
-> Filter snaps
-> Show total
-> add date filter
*/
