import { useCallback, useEffect, useState } from 'react'
import firebase from '../lib/firebase'

const useUsers = () => {
  //  --- Variables ---
  const [users, setUsers] = useState([])

  //  --- Setup ---
  useEffect(() => {
    firebase
      .functions()
      .httpsCallable('getUsers')()
      .then(({ data }) => setUsers(data.users))
  }, [])

  const refetch = useCallback(() => {
    firebase
      .functions()
      .httpsCallable('getUsers')()
      .then(({ data }) => setUsers(data.users))
  }, [])

  const updateUser = useCallback((form) => {
    return firebase.functions().httpsCallable('updateUser')(form)
  }, [])

  const toggleDisabled = useCallback((form) => {
    return firebase.functions().httpsCallable('toggleDisabled')(form)
  }, [])

  const createUser = useCallback((form) => {
    console.log('Creating user', form)
    return firebase.functions().httpsCallable('createUser')(form)
  }, [])

  //  --- Response ---
  return { users, refetch, updateUser, createUser, toggleDisabled }
}

export default useUsers
