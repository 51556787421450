import React, { useMemo } from 'react'
import { TableDropdown } from 'components'
import { useTable, useSortBy, useFlexLayout, useExpanded } from 'react-table'
import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Trans } from 'react-i18next'
import './Table.css'

const ReactTable = ({
  initialSort,
  rowOnClick,
  overrideOnClick = [],
  downloadFile,
  title,
  searchKey,
  ...props
}) => {
  //  --- Variables ---
  const columns = useMemo(() => props.columns, [props.columns])
  const data = useMemo(() => props.data, [props.data])

  //  --- Setup ---
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [initialSort],
      },
    },
    useSortBy,
    useFlexLayout,
    useExpanded
  )

  //  --- Response ---
  return (
    <div className='flex flex-col overflow-scroll'>
      <div className='flex flex-row justify-between mb-2 space-x-4'>
        {/* <p className='my-auto text-2xl'>{title || ''}</p> */}
        {props.children ? <div className='flex flex-1'>{props.children}</div> : null}
        {downloadFile ? <TableDropdown filename={downloadFile()} data={data} /> : null}
      </div>
      <div className='z-0'>
        <table className='w-full data-table text-sm' {...getTableProps()}>
          <thead className='table-header-group'>
            {headerGroups.map((headerGroup) => {
              return (
                <tr className='text-left text-gray-600' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th {...header.getHeaderProps(header.getSortByToggleProps())}>
                        <div className='flex flex-row justify-between'>
                          {header.i18n ? (
                            <Trans i18nKey={header.i18n}>{header.render('Header')}</Trans>
                          ) : (
                            header.render('Header')
                          )}
                          {header.isSorted ? (
                            header.isSortedDesc ? (
                              <SortDescendingIcon className='w-5 h-5' />
                            ) : (
                              <SortAscendingIcon className='w-5 h-5' />
                            )
                          ) : null}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody className='border-t border-b border-gray-400 ' {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  {...(props.expandOnRowClick ? row.getToggleRowExpandedProps({}) : {})}
                  className={
                    props.expandOnRowClick || rowOnClick
                      ? `hover:bg-gray-50 cursor-pointer ${row.isExpanded ? 'bg-indigo-50' : ''}`
                      : undefined
                  }
                  {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        onClick={() =>
                          rowOnClick && !overrideOnClick.includes(index)
                            ? rowOnClick(row.original)
                            : undefined
                        }
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ReactTable
