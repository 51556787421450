import React, { useState } from 'react'
import { Card, ReactTable } from 'components'
import MenuDropdown from 'components/Dropdowns/MenuDropdown'
import { toast } from 'react-hot-toast'
import { useEmployees } from 'context'
import { Modal } from 'components/Modal'
import { Button, TextInput } from 'components'

const EditEmployeeForm = ({ employee, onClose, onSubmit }) => {
  const [form, setForm] = useState(employee)

  const submit = () => {
    onSubmit(form)
  }

  return (
    <>
      <div className='flex flex-col justify-between mt-3 gap-4'>
        <div>
          <span className='flex flex-col justify-center w-full'>Name:</span>
          <div className='flex flex-row justify-between gap-3'>
            <TextInput
              type='text'
              value={form.fullName}
              onChange={(e) => setForm((f) => ({ ...f, fullName: e.target.value }))}
            />
          </div>
        </div>
        <div>
          <span className='flex flex-col justify-center w-full'>Employee Number:</span>
          <div className='flex flex-row justify-between gap-3'>
            <TextInput
              type='text'
              placeholder='Optional'
              value={form.employeeNum}
              onChange={(e) => setForm((f) => ({ ...f, employeeNum: e.target.value }))}
            />
          </div>
        </div>
        <div>
          <span className='flex flex-col justify-center w-full'>Hourly Rate:</span>
          <div className='flex flex-row justify-between gap-3'>
            <TextInput
              type='number'
              placeholder='Optional'
              value={form.rate}
              onChange={(e) => setForm((f) => ({ ...f, rate: e.target.value }))}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-end space-x-2 mt-3'>
        <Button secondary={true} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={submit}>Save</Button>
      </div>
    </>
  )
}

export default function Employees() {
  const { faces, updateEmployee } = useEmployees()
  const [selected, setSelected] = useState()

  const onEdit = (row) => {
    setSelected(row)
  }

  const onCancelEdit = () => {
    setSelected()
  }

  const onEditSubmit = async (updates) => {
    await toast.promise(updateEmployee(updates), {
      success: 'Saved changes',
      error: 'Could not update employee',
    })
    setSelected()
  }

  return (
    <Card>
      {faces.length ? (
        <ReactTable
          columns={columns(onEdit)}
          data={faces}
          initialSort={{ id: 'fullName', desc: true }}
          showDownload={true}
          title='Registered Employees'
        />
      ) : null}

      <Modal isOpen={!!selected} setIsOpen={onCancelEdit} title='Edit employee' size={'1/2'}>
        {selected ? (
          <EditEmployeeForm employee={selected} onClose={onCancelEdit} onSubmit={onEditSubmit} />
        ) : null}
      </Modal>
    </Card>
  )
}

const columns = (onEditEmployee) => [
  {
    i18n: 'faces:name',
    accessor: 'fullName',
    Header: 'Name',
  },
  {
    i18n: 'faces:employeeNum',
    accessor: 'employeeNum',
    Cell: ({ row: { original } }) => (original.employeeNum ? original.employeeNum : '-'),
  },
  {
    i18n: 'faces:rate',
    accessor: 'rate',
    Cell: ({ row: { original } }) => (original.rate ? original.rate : '-'),
  },
  {
    i18n: 'faces:status',
    accessor: 'status',
    Header: 'Status',
  },
  {
    i18n: 'snapshots:location',
    Header: 'Actions',
    accessor: 'actions',
    width: 100,
    Cell: ({ row: { original } }) => (
      <div>
        <MenuDropdown
          variant='secondary'
          items={[
            {
              label: 'Edit',
              onClick: () => onEditEmployee(original),
            },
            {
              label: 'Delete',
              onClick: () => {
                console.log('AWE')
              },
            },
          ]}>
          Actions
        </MenuDropdown>
      </div>
    ),
  },
  // {
  //   i18n: 'faces:imageId',
  //   accessor: 'imageId',
  //   Header: 'Image ID',
  // },
]
