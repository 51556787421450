import React, { useRef, useState, useEffect } from 'react'
import { useDeepCompareEffectForMaps } from './utils'

const Map = ({
  onClick,
  onIdle,
  children,
  className,
  style,
  defaultCenter = { lat: 0, lng: 0 },
  defaultZoom = 5,
  ...options
}) => {
  const ref = useRef(null)
  const [map, setMap] = useState()

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: defaultCenter,
          zoom: defaultZoom,
        })
      )
    }
  }, [ref, map, defaultCenter, defaultZoom])

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  useEffect(() => {
    if (map) {
      ;['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      )
      if (onClick) {
        map.addListener('click', onClick)
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle])

  return (
    <>
      <div className={className} ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}

export default Map
