import React, { useState, useEffect, useCallback } from 'react'
import firebase from '../../lib/firebase'
import { toast } from 'react-hot-toast'
import { useAuth } from 'context'
import { Card } from 'components'

// components

import CardSettings from 'components/Cards/CardSettings.js'

export default function Settings() {
  //  --- Variables ---
  const db = firebase.firestore()
  const { user } = useAuth()
  const [userCollection, setUserCollection] = useState()

  //  --- Functionality ----
  const onChange = (e) => {
    const { value, name } = e.target
    setUserCollection({ ...userCollection, [name]: value })
  }

  const fetchUser = useCallback(async () => {
    const response = db.collection('users').where('userId', '==', user.uid)
    const data = await response.get()
    data.docs.forEach((item) => {
      setUserCollection({
        uid: item.id,
        ...item.data(),
      })
    })
  }, [db, user])

  const updateUser = async (updatedUser) => {
    await firebase.firestore().collection('users').doc(userCollection.uid).update(updatedUser)

    toast.success('Saved account changes')
  }

  //  --- Setup ---
  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  //  --- Response ---
  return (
    <Card>
      <CardSettings
        user={{
          // userName: userCollection && userCollection.userName,
          email: user.email,
          firstName: userCollection && userCollection.firstName,
          lastName: userCollection && userCollection.lastName,
          address: userCollection && userCollection.address,
          city: userCollection && userCollection.city,
          country: userCollection && userCollection.country,
          postalCode: userCollection && userCollection.postalCode,
          about: userCollection && userCollection.about,
        }}
        onChange={onChange}
        updateUser={updateUser}
      />
    </Card>
  )
}
