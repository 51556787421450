import React from 'react'

const TextInput = ({ children, className, defaultValue, onChange, reference, ...props }) => {
  return (
    <input
      className={`px-3 py-2 border border-gray-200 placeholder-gray-400 text-gray-700 ${
        props.disabled ? 'bg-gray-50' : 'bg-white'
      } rounded-lg text-sm focus:outline-none w-full focus:border-indigo-600 ${className || ''}`}
      defaultValue={defaultValue}
      ref={reference}
      onChange={onChange}
      {...props}>
      {children}
    </input>
  )
}

export default TextInput
