import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)

const formatTimeDiff = (diff) => {
  return `${String(diff.hour).padStart(2, '0')}:${String(diff.minute).padStart(2, '0')}:${String(
    diff.second
  ).padStart(2, '0')}`
}

const timeDiff = (startTime, endTime) => {
  const diffSeconds = Math.abs(dayjs(endTime).unix() - dayjs(startTime).unix())
  const hour = Math.floor(diffSeconds / 3600)
  const minute = Math.floor((diffSeconds - hour * 3600) / 60)
  const second = diffSeconds - hour * 3600 - minute * 60

  return {
    hour,
    minute,
    second,
  }
}

const Duration = ({ from, to }) => {
  const [val, setVal] = useState(formatTimeDiff(timeDiff(from, to)))

  useEffect(() => {
    let inter
    if (!to) {
      inter = setInterval(() => {
        setVal(formatTimeDiff(timeDiff(from, to)))
      }, 1000)
    }

    return () => clearInterval(inter)
  }, [setVal, from, to])

  return val
}

// export { timeDiff, useTimer };

// const Duration = ({ to, from }) => {
//   const [val, setVal] = useState(dayjs(dayjs(to).diff(from)).utc().format('HH:mm:ss'))

//   useEffect(() => {
//     let inter
//     if (!to) {
//       inter = setInterval(() => {
//         const newVal = dayjs(dayjs().diff(from)).utc().format('HH:mm:ss')
//         setVal(newVal)
//       }, 1000)
//     }

//     return () => clearInterval(inter)
//   }, [setVal, from, to])

//   return val
// }

export default Duration
