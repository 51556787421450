import React, { useMemo, useState } from 'react'

import * as Google from './Google'

const DashboardMap = ({ defaultCenter = { lat: 0, lng: 0 }, sessions, locations }) => {
  const [loaded, setLoaded] = useState(false)
  const sessionLocations = useMemo(() => {
    return sessions
      .filter((s) => s.latitude && s.longitude)
      .map((session) => ({
        title: session.fullName,
        name: session.fullName,
        position: { lat: session.latitude, lng: session.longitude },
      }))
  }, [sessions])

  //  --- Response ---
  // if (!clusterData.initialized) return null
  return (
    <div className='rounded-lg overflow-hidden shadow-lg'>
      <Google.Container className='w-full' style={{ height: 600 }} onIdle={(e) => setLoaded(true)}>
        {loaded && sessionLocations.length > 0 ? (
          <Google.Bounds locations={sessionLocations.map((s) => s.position)} padding={20} />
        ) : null}
        <Google.MarkerCluster markers={sessionLocations} />
        {/* {sessions.map((session, idx) => (
          <Google.Marker
            key={idx}
            title={session.fullName}
            name={session.fullName}
            position={{ lat: session.latitude, lng: session.longitude }}
          />
        ))} */}
        {/* </Google.MarkerCluster> */}
      </Google.Container>
    </div>
  )
}

export default DashboardMap
