import React, { useMemo } from 'react'
import { Card, ReactTable } from 'components'
import { useQueryParam } from 'hooks'
import { getDate } from 'helpers/DateTimeHelper'
import { DebounceTextInput } from 'components/Inputs/DebounceTextInput'
import { ExclamationIcon } from '@heroicons/react/solid'
import { useSessions } from 'hooks'
import dayjs from 'dayjs'
import MenuDropdown from 'components/Dropdowns/MenuDropdown'
import { DatePicker } from 'components/Inputs/DatePicker'
import { exportToXLSX } from 'lib/exports'
import { exportToCSV } from 'lib/exports'
import { useEmployees } from 'context'
import { getVal, formatNumber, filterData } from 'helpers/ApplicationHelper'

export default function Reports() {
  const [from, setFrom] = useQueryParam('from', getDate('thisWeek'))
  const [to, setTo] = useQueryParam('to', getDate())
  const [query, setQuery] = useQueryParam('filter')

  const { faces } = useEmployees()

  const { sessions } = useSessions(dayjs(from).valueOf(), dayjs(to).endOf('day').valueOf(), true)

  const filteredData = useMemo(() => {
    const employeeMap = {}
    for (let i = 0; i < faces.length; i++) {
      employeeMap[faces[i].id] = faces[i]
    }

    const filtered = filterData(sessions, query).filter((sesh) => sesh.status === 'done')
    const total = filtered.reduce((a, b) => a + b.duration, 0).toFixed(2)
    const cost = filtered
      .reduce((a, b) => a + b.duration * getVal(employeeMap[b.faceId], 'rate', 0), 0)
      .toFixed(2)

    return {
      data: filtered,
      cost: formatNumber(cost),
      total,
    }
  }, [sessions, faces, query])

  const fromDate = dayjs(from, 'YYYY-MM-DD').toDate()
  const toDate = dayjs(to, 'YYYY-MM-DD').toDate()

  return (
    <Card>
      {/* <div className='font-bold text-2xl pb-6'>
        Total hours: <span className='text-indigo-700'>{filteredData.total}</span>
      </div> */}
      <div className='flex flex-row flex-wrap space-x-10'>
        <div className='font-bold text-2xl'>
          Total hours: <span className='text-indigo-700'>{filteredData.total}</span>
        </div>
        <div className='font-bold text-2xl pb-6'>
          Total cost: <span className='text-indigo-700'>{filteredData.cost}</span>
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={filteredData.data}
        initialSort={{ id: 'endTime', desc: true }}>
        <div className='flex flex-row space-x-2 flex-grow'>
          <div className='flex flex-grow'>
            <DebounceTextInput defaultValue={query} placeholder='Search...' onChange={setQuery} />
          </div>
          <div className='flex flex-row space-x-2'>
            <DatePicker
              value={fromDate}
              onSubmit={(v) => setFrom(dayjs(v).format('YYYY-MM-DD'))}
              maxDate={toDate}
              dateFormat='dd-MM-yyyy'
            />
            <DatePicker
              value={toDate}
              onSubmit={(v) => setTo(dayjs(v).format('YYYY-MM-DD'))}
              minDate={fromDate}
              dateFormat='dd-MM-yyyy'
            />
          </div>
          <MenuDropdown
            variant='primary'
            items={[
              {
                label: 'Excel (.xlsx)',
                onClick: () => {
                  const dataUrl = exportToXLSX(filteredData.data, faces)
                  const a = document.createElement('a')
                  a.download = `Snapwage Times ${query ? query + '_' : ''}${from}_${to}.xlsx`
                  a.href = dataUrl
                  a.click()
                },
              },
              {
                label: 'Comma Separated (.csv)',
                onClick: () => {
                  const dataUrl = exportToCSV(filteredData.data, faces)
                  const a = document.createElement('a')
                  a.download = `Snapwage Times ${query ? query + '_' : ''}${from}_${to}.csv`
                  a.href = dataUrl
                  a.click()
                },
              },
            ]}>
            Export
          </MenuDropdown>
        </div>
      </ReactTable>
    </Card>
  )
}

const columns = [
  {
    i18n: 'snapshots:name',
    accessor: 'fullName',
    Header: 'Name',
    Cell: ({ row: { original } }) => (
      <div className='flex flex-col justify-center h-full'>{original.fullName}</div>
    ),
  },
  {
    i18n: 'snapshots:startTime',
    accessor: 'startTime',
    Header: 'Start',
    Cell: ({ row: { original } }) => (
      <div className='flex flex-col justify-center h-full'>
        {dayjs(original.startTime).format('DD-MM-YYYY HH:mm:ss')}
      </div>
    ),
  },
  {
    i18n: 'snapshots:endTime',
    accessor: 'endTime',
    Header: 'End',
    Cell: ({ row: { original } }) => (
      <div className='flex flex-col justify-center h-full'>
        {original.endTime ? dayjs(original.endTime).format('DD-MM-YYYY HH:mm:ss') : '-'}
      </div>
    ),
  },
  {
    i18n: 'snapshots:duration',
    accessor: 'duration',
    Header: 'Duration',
    Cell: ({ row: { original } }) => {
      return (
        <div className='flex flex-row py-2'>
          {original.duration ? original.duration.toFixed(2) : '-'}
          {!!original.audit ? (
            <ExclamationIcon className='h-4 w-4 text-yellow-400 my-auto ml-2' />
          ) : null}
        </div>
      )
    },
  },
  {
    i18n: 'snapshots:duration',
    accessor: 'coordinates',
    Cell: ({ row: { original } }) => (
      <div className='flex flex-col justify-center h-full'>
        <a
          className='text-indigo-600'
          target='_blank'
          rel='noreferrer'
          href={`https://www.google.com/maps/place/${original.latitude},${original.longitude}`}>
          {original.latitude}, {original.longitude}
        </a>
      </div>
    ),
  },
  {
    i18n: 'snapshots:location',
    accessor: 'geozone',
    Header: 'Location',
    Cell: ({ row: { original } }) => (
      <div className='flex flex-col justify-center h-full'>{original.geozone}</div>
    ),
  },
]

/*
-> Filter snaps
-> Show total
-> add date filter
*/
