import { write, utils } from 'xlsx'
import Papa from 'papaparse'
import dayjs from 'dayjs'

const formatDateForExport = (dt) => {
  const value = dayjs(dt)
  if (value.isValid()) {
    return value.format('YYYY-MM-DD HH:mm:ss')
  } else {
    return ''
  }
}

const getAmountForEntry = (rate, duration) => {
  return rate ? parseFloat(rate) * duration : ''
}

const exportToXLSX = (sessions, employees) => {
  let employeeMap = {}
  for (let e of employees) {
    const { id, ...emp } = e
    employeeMap[id] = emp
  }

  const data = sessions.map((session) => [
    employeeMap[session.faceId].employeeNum || '',
    session.fullName,
    formatDateForExport(session.startTime),
    formatDateForExport(session.endTime),
    session.duration,
    session.geozone,
    employeeMap[session.faceId].rate || '',
    getAmountForEntry(employeeMap[session.faceId].rate, session.duration),
  ])

  const result = [
    ['Employee Number', 'Full name', 'Started', 'Ended', 'Hours', 'Project', 'Rate', 'Amount'],
    ...data,
  ]
  var workbook = utils.book_new()
  const worksheet = utils.aoa_to_sheet(result, { dense: true, raw: true })
  workbook.SheetNames.push('Sheet 1')
  workbook.Sheets['Sheet 1'] = worksheet

  const binary = write(workbook, {
    type: 'binary',
    bookType: 'xlsx',
  })

  let buf = new ArrayBuffer(binary.length) //convert s to arrayBuffer
  let buffer = new Uint8Array(buf) //create uint8array as viewer
  for (var i = 0; i < binary.length; i++) buffer[i] = binary.charCodeAt(i) & 0xff //convert to octet

  return URL.createObjectURL(
    new Blob([buffer], {
      type: 'application/octet-stream',
    })
  )
}

const exportToCSV = (sessions, employees) => {
  let employeeMap = {}
  for (let e of employees) {
    const { id, ...emp } = e
    employeeMap[id] = emp
  }

  const data = sessions.map((session) => [
    employeeMap[session.faceId].employeeNum || '',
    session.fullName,
    formatDateForExport(session.startTime),
    formatDateForExport(session.endTime),
    session.duration,
    session.geozone,
    employeeMap[session.faceId].rate || '',
    getAmountForEntry(employeeMap[session.faceId].rate, session.duration),
  ])

  const csv = Papa.unparse([
    ['Employee Number', 'Full name', 'Started', 'Ended', 'Hours', 'Project', 'Rate', 'Amount'],
    ...data,
  ])

  return URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
}

export { exportToCSV, exportToXLSX }
