import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { UserDropdown } from 'components'
import { useTranslation } from 'react-i18next'

export default function Sidebar() {
  //  --- Variables ---
  const { t } = useTranslation()
  const [collapseShow, setCollapseShow] = React.useState('hidden')

  //  --- Response ---
  return (
    <nav className='bg-white md:w-64 shadow-xl w-full' style={{ minWidth: 256 }}>
      <div className='px-6 py-4 flex flex-col justify-between h-full'>
        <div className='md:flex-col justify-between flex w-full mx-auto'>
          {/* Toggler */}
          <button
            className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow('bg-white m-2 md:m-0 py-3 md:p-0 px-6')}>
            <i className='fas fa-fw fa-bars'></i>
          </button>
          {/* Brand */}
          <Link
            className='md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
            to='/'>
            Snapwage
          </Link>
          {/* User */}
          <ul className='md:hidden items-center flex flex-wrap list-none'>
            <li className='inline-block relative'>
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                  <Link
                    className='md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
                    to='/'>
                    Snapwage
                  </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow('hidden')}>
                    <i className='fas fa-fw fa-times'></i>
                  </button>
                </div>
              </div>
            </div>
            <hr className='hidden md:block my-4 md:min-w-full' />
            <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/dashboard'>
                  <i className='fas fa-fw fa-house mr-2 text-sm'></i>
                  {t('application:dashboard')}
                </NavLink>
              </li>

              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/snapshots'>
                  <i className='fas fa-fw fa-stopwatch mr-2 text-sm'></i>
                  {t('application:times')}
                </NavLink>
              </li>

              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/reports'>
                  <i className='fas fa-fw fa-chart-line mr-2 text-sm'></i>
                  Reports
                </NavLink>
              </li>

              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/users'>
                  <i className='fas fa-fw fa-user-shield mr-2 text-sm'></i>
                  Users
                </NavLink>
              </li>

              {/* <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/machines'>
                  <i className='fas fa-fw fa-compass mr-2 text-sm '></i>
                  Machines
                </NavLink>
              </li> */}

              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/locations'>
                  <i className='fas fa-fw fa-location-crosshairs mr-2 text-sm'></i>
                  Locations
                </NavLink>
              </li>

              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/faces'>
                  <i className='fas fa-fw fa-face-smile mr-2 text-sm'></i>
                  Employees
                </NavLink>
              </li>

              <li className='items-center'>
                <NavLink
                  className='text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600'
                  activeClassName='text-indigo-500 hover:text-indigo-600'
                  to='/admin/settings'>
                  <i className='fas fa-fw fa-sliders mr-2 text-sm'></i>
                  {t('application:account')}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <span className='text-xs text-gray-500'>v1.1.2</span>
      </div>
    </nav>
  )
}
