import { toLocalTime } from 'helpers/DateTimeHelper'
import { useEffect, useState } from 'react'
import { Collections } from '../config/Constants'
import firebase from '../lib/firebase'

const useSnapsAndSessions = (user, from, to, approved) => {
  //  --- Variables ---
  const [snaps, setSnaps] = useState([])
  const [sessions, setSessions] = useState([])

  //  --- Setup ---
  useEffect(() => {
    if (user == null) return

    let filter = firebase.firestore().collection(Collections.SNAPS).where('orgId', '==', user.orgId)

    if (from) {
      filter = filter.where('createdAt', '>=', from)
    }

    if (to) {
      filter = filter.where('createdAt', '<', to)
    }

    filter.onSnapshot((querySnapshot) => {
      if (querySnapshot.empty) return

      setSnaps(
        querySnapshot.docs.map((document) => {
          const { capturedAt, status, lat, lng, ...snapData } = document.data()

          return {
            ...snapData,
            capturedAt: toLocalTime(capturedAt),
            id: document.id,
            lat: parseFloat(lat?.toFixed(6)),
            lng: parseFloat(lng?.toFixed(6)),
            status: status?.toUpperCase(),
          }
        })
      )
    }, console.error)
  }, [user, from, to])

  useEffect(() => {
    if (user != null) {
      let query = firebase.firestore().collection('sessions').where('orgId', '==', user.orgId)

      if (from) {
        query = query.where('createdAt', '>=', from)
      }

      if (to) {
        query = query.where('createdAt', '<', to)
      }

      // if (approved === false) {
      //   query = query.where('status', '==', 'done')
      // }

      const subscription = query
        .where('approved', '==', approved)
        .orderBy('createdAt', 'desc')
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.empty) setSessions([])

          setSessions(
            querySnapshot.docs.map((session) => {
              const sessionData = session.data()

              return {
                ...sessionData,
                status: sessionData.status,
                id: session.id,
              }
            })
          )
        }, console.error)

      return subscription
    }
  }, [user, from, to, approved])

  //  --- Response ---
  return { snaps, sessions }
}

export default useSnapsAndSessions
