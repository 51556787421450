import { useLayoutEffect } from 'react'

const Bounds = ({ map, padding = 0, locations = [] }) => {
  useLayoutEffect(() => {
    if (locations.length > 0 && map != null) {
      let mapBounds = new window.google.maps.LatLngBounds()
      locations.forEach((pos) => {
        mapBounds.extend(new window.google.maps.LatLng(pos))
      })

      var zoomChangeBoundsListener = window.google.maps.event.addListener(
        map,
        'bounds_changed',
        function (event) {
          window.google.maps.event.removeListener(zoomChangeBoundsListener)
          map.setZoom(Math.min(18, map.getZoom()))
        }
      )

      map.setCenter(mapBounds.getCenter())
      map.fitBounds(mapBounds, padding)
    }
  }, [map, padding, locations])

  return null
}

export default Bounds
